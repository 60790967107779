import style from './common.module.css'
import type { ImgHTMLAttributes, SyntheticEvent } from 'react'

export type AspectRatio = '32/9' | '16/9' | '1/1' | '2/1'

type CustomProps = {
  alt: string
  src: string
  isFadeIn?: boolean
  aspectRatio?: AspectRatio
  fallbackSrc?: string
  backgroundColor?: string
  className?: string
}

type OtherImgProps = Omit<ImgHTMLAttributes<HTMLImageElement>, keyof CustomProps>

export type Props = CustomProps & OtherImgProps

const defaultFallbackSrcMap: Record<AspectRatio, string> = {
  ['32/9']: '/_fallbacks/32-9.svg',
  ['16/9']: '/_fallbacks/16-9.svg',
  ['1/1']: '/_fallbacks/1-1.svg',
  ['2/1']: '/_fallbacks/2-1.svg',
}

export function Img({
  alt,
  src,
  isFadeIn = true,
  aspectRatio = '16/9',
  fallbackSrc = defaultFallbackSrcMap[aspectRatio],
  backgroundColor = 'var(--vidzingBlackRussian)',
  className = style.defaultClassName,
  ...otherImgProps
}: Props): JSX.Element {
  const showImg = (event: SyntheticEvent) => {
    event.currentTarget.classList.remove(style.unloaded)
  }

  const addFallbackSrc = ({ currentTarget }: SyntheticEvent<HTMLImageElement, Event>): void => {
    const srcPathname = new URL(currentTarget.src).pathname
    if (srcPathname !== fallbackSrc) {
      currentTarget.src = fallbackSrc
    }
  }

  const cssVariables: Record<string, unknown> = {
    '--placeholderBackgroundColor': backgroundColor,
    '--aspectRatio': aspectRatio,
  }

  return (
    <div className={[style.placeholder, className].join(' ')} style={cssVariables}>
      {src && (
        <img
          alt={alt}
          src={src}
          className={[style.img, isFadeIn ? style.unloaded : ''].join(' ')}
          draggable={false}
          onLoad={isFadeIn ? showImg : undefined}
          onError={fallbackSrc ? addFallbackSrc : undefined}
          loading="lazy"
          {...otherImgProps}
        />
      )}
    </div>
  )
}
